<template>
  <SaveCategory
    @saveDetail="saveCategory"
    @cancelCreate="cancelCreate"
    :categoryDetail="categoryDetail"
    :update="false"
  />
</template>

<script>
import SaveCategory from "../../../components/WebStore/categories/add";
import { mapActions } from "vuex";

export default {
  name: "Add Category",
  components: {
    SaveCategory,
  },
  data: () => ({
    categoryDetail: {
      name: "",
      slug: "",
      parentCategory: "",
      productDescription: "",
      matchingCondition: "",
      conditions: [],
      image: "",
      categoryVisibility: "public",
      desktopImage: '',
      mobileImage: ''
    },
  }),
  methods: {
    ...mapActions("category", ["addCategory"]),
    ...mapActions("ecommerce",["algoliaSync"]),
    saveCategory(data) {
      let self = this;
      this.addCategory(data).then((res) => {
        this.algoliaSync()
        self.$vs.notify({
          title: "Category Add",
          text: "Category added successfully",
          color: "success",
        });
        self.$vs.loading.close();
        this.$router.push({ name: "CategoryManagement" });
      }).catch((err) => {
        console.error("ERR: ", err);
        self.$vs.notify({
          title: "Category Add",
          text: err.data.message || "Failed",
          color: "danger",
        })
      });
    },
    cancelCreate() {
      this.$router.push({ name: "CategoryManagement" });
    },
  },
};
</script>
